<template>
  <v-card>
    <v-card-title class="text-h5 mb-5"> Publisher Address </v-card-title>

    <v-row no-gutters>
      <v-col cols="12">
        <div class="dialog-form px-5 mb-5">
          <span class="mb-3">Province</span>
          <v-select
            :items="publisher_types"
            item-text="state"
            item-value="abbr"
            label="Select Province"
            return-object
            single-line
            solo
            hide-details="auto"
          ></v-select>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="dialog-form px-5 mb-5">
          <span class="mb-3">City</span>
          <v-select
            :items="publisher_types"
            item-text="state"
            item-value="abbr"
            label="Select City"
            return-object
            single-line
            solo
            hide-details="auto"
          ></v-select>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="dialog-form px-5 mb-5">
          <span class="mb-3">Sub Distirct</span>
          <v-select
            :items="publisher_types"
            item-text="state"
            item-value="abbr"
            label="Select District"
            return-object
            single-line
            solo
            hide-details="auto"
          ></v-select>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="dialog-form px-5 mb-5">
          <span class="mb-3">Urban village</span>
          <v-text-field
            solo
            hide-details="auto"
            value="Achmad Nurul Anwar"
          ></v-text-field>
        </div>
      </v-col>

      <v-col cols="12">
        <div class="dialog-form px-5 mb-5">
          <span class="mb-3">Full Address</span>
          <v-textarea
            solo
            label="Full address"
            value="Jln. Haji Sidiq, desa Walangsanga Mijen. Rt37, Rw09. Kecamatan Moga, Kabupaten Pemalang."
          ></v-textarea>
        </div>
      </v-col>
    </v-row>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" text @click="changeAddress = false">
        Cancel
      </v-btn>

      <v-btn color="primary" @click="dialog = false"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["kelurahan", "kecamatan", "kota", "provinsi", "address"],
  data() {
    return {
      selected_type: { abbr: this.publisher },
      publisher_name: this.name,
      publisher_types: [
        { state: "Perseroan Terbatas (PT)", abbr: "PT" },
        { state: "Commanditaire Vennonstchap (CV)", abbr: "CV" },
        { state: "Usaha Dagang (UD)", abbr: "UD" },
        { state: "Perguruan Tinggi", abbr: "Perguruan Tinggi" },
        { state: "Yayasan", abbr: "Yayasan" },
        { state: "Self Publishing", abbr: "Self Publishing" },
      ],

      submit: false,
    };
  },
  methods: {
    closeNameModal() {
      this.$emit("close-dialog");
    },
    showChangeName() {
      this.changeName = true;
    },

    updateName() {
      this.submit = true;
      var token = localStorage.getItem("access_token");

      axios
        .post(
          "updateName",
          {
            type: this.selected_type.abbr,
            name: this.publisher_name,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          this.data = response.data.data.user;
          this.$emit("close-dialog");
          this.$emit("updated-data");
          console.log(this.data);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submit = false));
    },
  },
};
</script>

<template>
  <div class="content">
    <div class="row">
      <div class="col-12 col-md-3">
        <v-card :loading="submitImage" class="mx-auto">
          <v-img
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
            v-if="!data.image"
          ></v-img>

          <v-img
            :src="data.image"
            v-else
          ></v-img>

          <div class="change-picture px-3 mt-3">
            <v-btn
              block
              outlined
              color="primary"
              :disabled="submitImage"
              @click="onButtonClick"
            >
              Change Publisher Logo
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </div>

          <v-card-text>
            <div class="text-caption">
              Besar file: maksimum 10.000.000 bytes (10 Megabytes). Ekstensi
              file yang diperbolehkan: .JPG .JPEG .PNG.
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div class="col-12 col-md-9" cols="12" md="9">
        <div class="personal-info mb-3">
          <div class="info-title text-overline font-weight-black mb-5">
            Personal Information
          </div>
          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Publisher Name
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{data.type}}. {{data.name}}
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeName = true"
                >Change</v-btn
              >
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Address
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{data.address}} {{data.kelurahan}}
            </div>
            <!-- <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeAddress = true"
                >Change</v-btn
              >
            </div> -->
          </div>
        </div>

        <div class="contact-info mb-3">
          <div class="info-title text-overline font-weight-black mb-5">
            Contact Information
          </div>
          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Email
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{data.email}}
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Person Responsible
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{data.penanggung_jawab}}
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Phone Number
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{data.phone}}
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeContact = true"
                >Change</v-btn
              >
            </div>
          </div>
        </div>

        <!-- <div class="mt-10">
          <v-btn outlined color="primary">Change Password</v-btn>
        </div> -->
      </div>
    </div>

    <v-dialog v-model="changeName" ref="changeName" max-width="400">
      <change-name :publisher="data.type" :name="data.name" v-on:close-dialog="closeNameDialog"  @updated-data="getData"/>
    </v-dialog>

    <v-dialog v-model="changeAddress" ref="changeAddress" max-width="400">
      <change-address
      :kelurahan="data.kelurahan"
      :kecamatan="data.kecamatan"
      :kota="data.kota"
      :provinsi="data.provinsi"
      :address="data.address"
      v-on:close-dialog="closeAddressDialog"  @updated-data="getData"/>
    </v-dialog>

    <v-dialog v-model="changeContact" max-width="400">
      <change-contact :pj="data.penanggung_jawab" :phone="data.phone" v-on:close-dialog="closeContactDialog"  @updated-data="getData"/>
    </v-dialog>
  </div>
</template>
<style>
.info-item-title {
  width: 200px;
}
</style>

<script>
import axios from "axios";
import ChangeName from "@/components/dashboard/modal/ChangeName.vue";
import ChangeAddress from "@/components/dashboard/modal/ChangeAddress.vue";
import ChangeContact from "@/components/dashboard/modal/ChangeContact.vue";
export default {
  props: {},
  components: {
    ChangeName,
    ChangeAddress,
    ChangeContact
  },
  data() {
    return {
      changeName: false,
      changeAddress: false,
      changeContact: false,
      loading: false,
      select: { state: "Florida", abbr: "FL" },

      isSelecting: false,
      submitImage: false,
      selectedFile: null,

      data: [],
      publisher_types: [
        { state: "Perseroan Terbatas (PT)", abbr: "PT" },
        { state: "Commanditaire Vennonstchap (CV)", abbr: "CV" },
        { state: "Usaha Dagang (UD)", abbr: "UD" },
        { state: "Perguruan Tinggi", abbr: "Perguruan Tinggi" },
        { state: "Yayasan", abbr: "Yayasan" },
        { state: "Self Publishing", abbr: "Self Publishing" },
      ],
    };
  },
  methods: {
    closeContactDialog(){
      this.changeContact = false;
    },
    closeAddressDialog(){
      this.changeAddress = false;
    },
    closeNameDialog(){
      this.changeName = false;
    },
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.submitImage = true;

      var fm = new FormData();
      fm.append("image", this.selectedFile);

      axios
        .post("updateImage", fm,{
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
          },
        })
        .then((response) => {
          this.data = response.data.data.user;
          console.log(this.data);
          this.getData()
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submitImage = false));

    },
    getData() {
      this.loading_data = true;
      var token = localStorage.getItem("access_token");

      axios
        .get("account", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then((response) => {
          this.data = response.data.data.user;
          console.log(this.data);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.loading_data = false));
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style scoped>
@import '../../../public/oneui/assets/css/oneui.min.css';
</style>
<template>
  <v-card>
    <form @submit.prevent="updateContact">
      <v-card-title class="text-h5 mb-5"> Contact Detail </v-card-title>

      <v-row no-gutters>
        <v-col cols="12">
          <div class="dialog-form px-5 mb-5">
            <span class="mb-3">Contact Number</span>
            <v-text-field
              solo
              hide-details="auto"
              :value="phone"
              v-model="telepon"
            ></v-text-field>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="dialog-form px-5 mb-5">
            <span class="mb-3">Person Responsible</span>
            <v-text-field solo hide-details="auto" :value="pj" v-model="penanggung_jawab"></v-text-field>
          </div>
        </v-col>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="closeContactModal">
          Cancel
        </v-btn>

        <v-btn color="primary" type="submit" :disabled="submit"> Save </v-btn>
      </v-card-actions>
    </form>
  </v-card>
</template>

<script>
import axios from "axios";
export default {
  props: ["pj", "phone"],
  data() {
    return {
      penanggung_jawab: this.pj,
      telepon: this.phone,

      submit: false,
    };
  },
  methods: {
    closeContactModal() {
      this.$emit("close-dialog");
    },
    updateContact() {
      this.submit = true;
      var token = localStorage.getItem("access_token");

      axios
        .post(
          "updateContact",
          {
            pj: this.penanggung_jawab,
            phone: this.telepon,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + token,
            },
          }
        )
        .then((response) => {
          this.data = response.data.data.user;
          this.$emit("close-dialog");
          this.$emit("updated-data");
          console.log(this.data);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submit = false));
    },
  },
};
</script>
